module.exports = {
  comm: {
    home: 'Startseite',
    pricing: 'Preise',
    blog: 'Stimmklonen & API', // 更新
    language: 'Sprache:',
    select_language: 'Sprache auswählen',
    txt2voice: 'Text zu Sprache',
    voice2txt: 'Sprache zu Text',
    voiceclone: 'Stimmenklonen',
    video2txt: 'Video zu Text',
    footer_help: 'Brauchen Sie Hilfe? Schreiben Sie uns:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Anmelden',
    login_desc: 'Melden Sie sich an, um auf Ihr TikTok Voice Generator-Konto zuzugreifen',
    logout: 'Abmelden',
  },
  txt2voice: {
    title: 'TikTok Voice Generator: Lustige TikTok AI-Stimmen erzeugen',
    description: 'Erzeugen und laden Sie lustige TikTok AI-Stimmen kostenlos herunter, wie z.B. jessie Stimme, C3PO Stimme, ghostface Stimme...',
    keywords: 'TikTok Voice Generator, TikTok AI Stimme, Tik Tok Voice Generator',
    main_title_p01: 'TikTok Voice Generator',
    main_title_p02: 'Erzeugen lustige TikTok Stimmen: jessie Stimme, C3PO Stimme, ghostface Stimme',
    main_textarea_holder: 'Text hier eingeben oder einfügen',
    main_genvoice: 'erzeugen',
    main_generating: 'wird erzeugt',
    main_input_empty: 'bitte Text eingeben oder einfügen',
    daily_usage_limit_msg: '1) Das tägliche Maximum der Nutzung wurde erreicht. Bitte kommen Sie morgen wieder. 2) Wenn Sie dringend mehr Nutzung benötigen, kontaktieren Sie uns bitte per E-Mail.',
    text_max_prompt1: 'Bitte begrenzen Sie die Wortanzahl auf',
    text_max_prompt2: 'Wörter oder weniger!',
    popup_nologin_title: 'Verbessern Sie Ihren Abonnementplan',
    popup_nologin_desc: 'Schalten Sie mehr Sprachgenerierungszeit frei, indem Sie auf ein Premium-Abonnement upgraden.',
    popup_nologin_btntext: 'Abonnementpläne erkunden',
    popup_nosub_title: 'Verbessern Sie Ihren Abonnementplan',
    popup_nosub_desc: 'Schalten Sie mehr Sprachgenerierungszeit frei, indem Sie auf ein Premium-Abonnement upgraden.',
    popup_nosub_btntext: 'Abonnementpläne erkunden',
    popup_sublimit_title: 'Abonnement aufgebraucht, bitte upgraden',
    popup_sublimit_desc: 'Verbessern Sie Ihr Erlebnis mit unseren Premium-Funktionen und unbegrenztem Zugang.',
    popup_sublimit_btntext: 'Mehr kaufen',
  },
  pricing: {
    new_features_alert: "📣 Wir haben 🎙️ Stimmklonen und 🤖 API-Dienste für zahlende Mitglieder eingeführt! (Nach der Zahlung senden Sie eine E-Mail an tiktokaivoicetool@gmail.com für Hilfe bei diesen Funktionen). Zusätzlich können Sie uns gerne für individuelle Anfragen kontaktieren😃", // 新增
    pricing_title: 'Von einzelnen Erstellern bis hin zu den größten Unternehmen haben wir einen Plan für Sie.',
    pricing_desc: 'Über 100 natürliche, menschenähnliche Stimmen. Über 15 Sprachen. Und fast die niedrigsten Preise für jeden',
    sub_free_name: 'Kostenlos',
    sub_free_desc: 'Für Einzelpersonen, die modernste KI-Audio ausprobieren möchten',
    sub_free_content: [
      '1000 Zeichen Limit pro Monat',
      '5 Generierungen pro Tag',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'Für Ersteller, die Premium-Inhalte für ein globales Publikum produzieren',
    sub_starter_content: [
      '1 Stimmklon',
      '300.000 Zeichen Limit pro Monat (ca. 7 Stunden Audio)',
      'Unbegrenzte Downloads',
      'E-Mail-Support innerhalb von 72 Stunden',
      'Vorrangiger Zugang zu neuen Stimmen und Funktionen',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Für Ersteller, die ihre Inhaltsproduktion ausbauen',
    sub_pro_content: [
      'API-Zugang',
      '3 Stimmklone',
      '1.000.000 Zeichen Limit pro Monat (ca. 24 Stunden Audio)',
      'Unbegrenzte Downloads',
      'E-Mail-Support innerhalb von 48 Stunden',
      'Vorrangiger Zugang zu neuen Stimmen und Funktionen',
    ],
    period_year: 'Jährlich',
    period_month: 'Monatlich',
    period_month_short: 'Mo',
    billed_year: 'Jährlich abgerechnet',
    most_popular: 'Am beliebtesten',
    discount_quantity: 'Sparen Sie 48$',
    buy_btntext: 'Loslegen',
    pay_succ: 'Zahlung erfolgreich!',
    pay_succ_desc: 'Vielen Dank für Ihren Kauf. Die Transaktion wurde erfolgreich verarbeitet.',
    pay_succ_btntext: 'Beginnen Sie mit der Nutzung von TikTok Voice',
    pay_fail: 'Zahlung fehlgeschlagen!',
    pay_fail_desc: "Entschuldigung, wir konnten Ihre Transaktion nicht verarbeiten. Bitte versuchen Sie es erneut oder kontaktieren Sie unser Support-Team (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Zurück zur Startseite',
  },
  setting: {
    setting: 'Einstellungen',
    setting_title: 'Verwalten Sie hier Ihr Konto, Ihre Nutzung und Ihr Abonnement.',
    basic_info: 'Grundlegende Informationen', // 新增，放在 setting_title 下方
    user_name: 'Name',
    user_email: 'E-Mail',
    user_account: 'Konto',
    user_subscript: 'Aktueller Plan',
    user_usage: 'Nutzung',
    manage_subscript: 'Abonnement verwalten',
    use_limit_promote: "Sie haben 5000 Zeichen Ihres monatlichen Limits von 10000 Zeichen verwendet.",
    not_logged_in: 'Sie sind nicht angemeldet', // 新增
    go_to_login: 'Zur Anmeldung gehen >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok Voice Generator",
    subtitle: "Melden Sie sich an, um auf Ihr TikTok Voice Generator-Konto zuzugreifen",
    alreadyLoggedIn: "Sie sind bereits angemeldet",
    goToHome: "Zur Startseite gehen"
  },
  faq: {
    quest1: 'TikTok Voice Generator - Bestes TikTok AI Stimme Tool',
    answer1_1: 'Es ist ein TikTok AI Stimmen-Generator-Tool, entwickelt mit der neuesten TikTok TTS Technologie. Es kann TikTok Stimmen erzeugen, wie z.B. die jessie Stimme (weibliche Stimme), siri Stimme, ghostface Stimme, C3PO Stimme (Roboterstimme), tiefe Stimme (Erzähler), skye Stimme, warme Stimme, bestie Stimme, Heldenstimme (chirisvoice), empathische Stimme, ernsthafte Stimme, joey Stimme, stitch Stimme, Stormtrooper Stimme (Star Wars), Rocket Stimme (Guardians of the Galaxy).',
    answer1_2: 'Die TikTok AI Stimmen, die bald unterstützt werden, umfassen: derek Stimme, trickster Stimme, austin butler Stimme, Nachrichtenreporter Stimme, adam Stimme, Hundestimme, miley cyrus Stimme, alexa Stimme, Alien Stimme, Tierstimme, Baby Stimme, Computerstimme, Chipmunk Stimme, Echo Stimme, npr Stimme.',
    answer1_3: 'Zusätzlich unterstützt das TikTok Stimmen-Generator-Tool mehrere andere Sprachen, einschließlich Chinesisch, Japanisch, Koreanisch, Vietnamesisch, Thailändisch, Hindi, Persisch, Russisch, Deutsch, Französisch, Rumänisch, Tschechisch, Spanisch, Portugiesisch, Bengalisch, Italienisch, Arabisch, Urdu, Traditionelles Chinesisch und Malaiisch.',
    answer1_4: 'Wenn Sie dringend eine bestimmte Stimme benötigen, schreiben Sie mir bitte eine E-Mail.',

quest2: 'Welche Vorteile hat der TikTok Voice Generator?',
    answer2_1: '- Das TikTok Voice Generator Tool kann verschiedene Arten von Stimmen erzeugen, die oft in TikTok Videos verwendet werden.',
    answer2_2: '- Es ist der neueste AI Stimmen-Generator, der menschenähnliche TikTok TTS Stimmen erzeugen kann.',
    answer2_3: '- Es ist bequemer für die Videobearbeitung auf einem PC.',
    answer2_4: '- Sie können einige Stimmen verwenden, die derzeit nicht in der TikTok APP TTS gefunden werden können.',

quest3: 'Wie benutzt man den TikTok Voice Generator?',
    answer3_1: 'Der TikTok Voice Generator ist sehr einfach zu bedienen:',
    answer3_2: '- Wählen Sie die Sprache und den Akzent der Stimme aus.',
    answer3_3: '- Geben Sie den Text ein, der in Sprache umgewandelt werden soll, in das Eingabefeld ein.',
    answer3_4: '- Drücken Sie die Schaltfläche erzeugen und warten Sie ein paar Sekunden.',
    answer3_5: '- Spielen oder laden Sie die TikTok AI Stimme herunter.',

quest4: 'Was sind die bekanntesten und lustigsten TikTok Stimmen?',
    answer4_1: 'Die bekannteste männliche TikTok Stimme ist die tiefe Stimme, offiziell als Erzähler bezeichnet.',
    answer4_2: 'Für die lustigsten TikTok Stimmen empfehle ich persönlich die ghostface Stimme und die C3PO Stimme. Diese werden oft für die Vertonung von lustigen TikTok Videos verwendet, weil sie leicht erkennbar sind und die Zuschauer schnell in den Bann ziehen können.',

quest5: 'Was sind die nervigsten TikTok Stimmen?',
    answer5_1: 'Ich glaube nicht, dass es wirklich eine "nervige" TikTok Stimme gibt.',
    answer5_2: 'Es geht mehr um den Kontext und den Inhalt, in dem die Stimme verwendet wird, was einige Menschen unangenehm machen kann. Zum Beispiel könnten einige Menschen die jessie Stimme als nervig empfinden, weil sie zu einer bestimmten Zeit zu häufig auf TikTok erschien. Aufgrund ihres Timbres und ihrer Qualität ist es jedoch tatsächlich ein fantastischer TikTok Stimmeffekt. Außerdem ist die TikTok Synchronsprecherin dahinter sehr professionell und postet auch ihre eigenen Videos auf TikTok.',
    answer5_3: 'Deshalb schlage ich vor, dass alle ihre Bedenken und Vorurteile beiseite legen, die Stimmen aus diesem Tool mehrmals anhören und geduldig den besten TikTok Stimmeffekt für sich selbst finden.',

quest6: 'Wie benutzt man den TikTok Voice Generator, um die jessie Stimme zu erzeugen?',
    answer6_1: '- Wählen Sie im ersten Dropdown-Menü des TikTok Voice Generator Tools English (US) aus, dann wählen Sie im anderen Dropdown-Menü die jessie Stimme (weibliche Stimme) aus.',
    answer6_2: '- Geben Sie den Text in das Eingabefeld ein und klicken Sie auf die Schaltfläche erzeugen.',
    answer6_3: '- Warten Sie ein paar Sekunden oder bis zu zehn Sekunden, dann hören Sie die AI Stimme. Finden Sie in der Bedienleiste den Knopf mit einem Pfeil nach unten und klicken Sie darauf, um sie herunterzuladen.',

quest7: 'Wie benutzt man den TikTok Voice Generator, um die C3PO Stimme zu erzeugen?',
    answer7_1: '- Wählen Sie im ersten Dropdown-Menü des TikTok Voice Generator Tools English (US) aus, dann wählen Sie im anderen Dropdown-Menü die C3PO Stimme (Roboterstimme) aus.',
    answer7_2: '- Geben Sie den Text in das Eingabefeld ein und klicken Sie auf die Schaltfläche erzeugen.',
    answer7_3: '- Warten Sie ein paar Sekunden oder bis zu zehn Sekunden, dann hören Sie die AI Stimme. Finden Sie in der Bedienleiste den Knopf mit einem Pfeil nach unten und klicken Sie darauf, um sie herunterzuladen.',

quest8: 'Wie benutzt man den TikTok Voice Generator, um die ghostface Stimme zu erzeugen?',
    answer8_1: '- Wählen Sie im ersten Dropdown-Menü des TikTok Voice Generator Tools English (US) aus, dann wählen Sie im anderen Dropdown-Menü die ghostface Stimme aus.',
    answer8_2: '- Geben Sie den Text in das Eingabefeld ein und klicken Sie auf die Schaltfläche erzeugen.',
    answer8_3: '- Warten Sie ein paar Sekunden oder bis zu zehn Sekunden, dann hören Sie die AI Stimme. Finden Sie in der Bedienleiste den Knopf mit einem Pfeil nach unten und klicken Sie darauf, um sie herunterzuladen.',

quest9: 'Wie benutzt man den TikTok Voice Generator, um die siri Stimme zu erzeugen?',
    answer9_1: '- Wählen Sie im ersten Dropdown-Menü des TikTok Voice Generator Tools English (US) aus, dann wählen Sie im anderen Dropdown-Menü die siri Stimme aus.',
    answer9_2: '- Geben Sie den Text in das Eingabefeld ein und klicken Sie auf die Schaltfläche erzeugen.',
    answer9_3: '- Warten Sie ein paar Sekunden oder bis zu zehn Sekunden, dann hören Sie die AI Stimme. Finden Sie in der Bedienleiste den Knopf mit einem Pfeil nach unten und klicken Sie darauf, um sie herunterzuladen.',

quest10: 'Wie füge ich den TikTok Stimmeffekt zu meinem TikTok Video hinzu?',
    answer10_1: 'Wenn Sie wissen möchten, wie man die offiziellen TikTok Stimmen auf TikTok verwendet, werde ich bald einen speziellen Blogpost zu diesem Thema schreiben.',
    answer10_2: 'Hier erkläre ich, wie man die Stimme nach dem Erzeugen und Herunterladen vom TikTok Voice Generator auf TikTok veröffentlicht.',
    answer10_3: '1. Wenn Sie die TikTok Stimme auf Ihrem PC erstellt haben und sie auf TikTok oder eine andere Video-Bearbeitungs-App auf Ihrem Telefon hochladen möchten, müssen Sie die Sprachdatei auf Ihr Telefon übertragen. Für iPhones können Sie airDrop für die Übertragung verwenden. Für Android-Telefone bin ich mit diesem Prozess nicht sehr vertraut, aber Sie werden sicherlich Methoden und Werkzeuge finden, um dies zu tun.',
    answer10_4: '2. Sobald die Sprachdatei auf Ihr Telefon übertragen wurde, können Sie TikTok öffnen:',
    answer10_5: '- Tippen Sie auf den "+"-Knopf am unteren Rand der Schnittstelle, wählen Sie das Video auf Ihrem Telefon aus.',
    answer10_6: '- Nachdem Sie das Video hochgeladen haben, finden Sie ein quadratisches Symbol auf der rechten Seite des Bildschirms, um die Bearbeitungsseite zu betreten.',
    answer10_7: '- Am unteren Rand der Seite finden Sie die Schaltfläche "Stimme hinzufügen" und tippen Sie darauf, um die gerade übertragene Sprachdatei auszuwählen.',
    answer10_8: '- In dieser Schnittstelle können Sie einige Anpassungen am Video vornehmen und dann auf den Knopf in der oberen rechten Ecke klicken, um es zu veröffentlichen.',

quest11: 'Ist der TikTok Voice kostenlos?',
    answer11: 'Ja, es ist ein kostenloser TikTok AI Stimme Text-zu-Sprache Generator.',
    
quest12: 'Wo kann ich eine beliebte TikTok Stimme für mein TikTok Video erzeugen?',
    answer12: 'Sie können https://tiktokvoice.net/ besuchen, um AI Stimmen von TikTok in Ihr Video hinzuzufügen.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}